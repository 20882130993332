const COLORS = {
  TRANSPARENT: {
    100: "#00000000",
  },
  BLACK: {
    100: "#222222",
    60: "#a7a7a7",
    40: "#bdbdbd",
    20: "#e9e9e9",
  },
  WHITE: {
    100: "#f2f2f2",
  },
  DARK_BLUE: {
    100: "#0F0B36",
    60: "#3F3C5E",
    40: "#9F9DAF",
    20: "#E7E7EB",
  },
  BLUE: {
    100: "#16006D",
    60: "#5C4D99",
    40: "#B9B3D3",
    20: "#E8E6F0",
  },
  BRIGHT_BLUE: {
    100: "#3843D0",
    60: "#8EA1FF",
    40: "#C7D0FF",
    20: "#E8ECFF",
  },
  RED: {
    100: "#B60B0B",
    60: "#F28E86",
    40: "#FBD4CB",
    20: "#FDEAE5",
  },
  ORANGE: {
    100: "#ff9900",
    60: "#FFB84D",
    40: "#FFD699",
    20: "#FFEBCC",
  },
  YELLOW: {
    100: "#FFD80E",
    60: "#ffe456",
    40: "#ffec87",
    20: "#fff7cf",
  },
  GREEN: {
    100: "#367633",
    60: "#81CB8E",
    40: "#BEE4C6",
    20: "#E9F6EC",
  },
};

export default COLORS;
