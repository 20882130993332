/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * Note: The APIs wrapPageElement and wrapRootElement exist in both the browser and Server-Side Rendering (SSR) APIs.
 * You generally should implement the same components in both gatsby-ssr.js and gatsby-browser.js so that pages
 * generated through SSR are the same after being hydrated in the browser.
 *
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import * as React from "react";
import hordetheme from "./src/styles/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { GatsbyBrowser } from "gatsby";
import {
  GoogleGlid,
  GoogleSrc,
  FacebookFbclid,
  getParam,
  getExpiryRecord,
} from "./src/utils/tracking";

// Wraps root component with ThemeProvider
export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={hordetheme}>
    <CssBaseline />
    {element}
  </ThemeProvider>
);

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // GTAG
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    
    gtag('consent', 'default', {
      ad_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied",
      ad_user_data: "denied",
    ad_personalization: "denied",
    'wait_for_update': 500
  });
    gtag('config', 'G-ZS245GQXEJ');
    // END GTAG
    
    // Freshworks widget
    if (!window.fwLoaded) {
      window.fwSettings = { 'widget_id': 80000009097 };
      !function() {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function() {
            n.q.push(arguments)
          };
          n.q = [], window.FreshworksWidget = n
        }
      }();
      
      const fwScript = document.createElement("script");
      fwScript.type = "text/javascript";
      fwScript.src = "https://euc-widget.freshworks.com/widgets/80000009097.js";
      fwScript.async = true;
      fwScript.defer = true;
      document.head.appendChild(fwScript);

      window.fwLoaded = true;
    }

    // Mailchimp
    if (!document.getElementById('mcjs')) {
      const mcScript = document.createElement("script");
      mcScript.id = "mcjs";
      mcScript.innerHTML = `
        !function(c,h,i,m,p){
          ((m = c.createElement(h)),
          (p = c.getElementsByTagName(h)[0]),
          (m.async = 1),
          (m.src = i),
          p.parentNode.insertBefore(m, p))
        }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/f90a8469aad1214a51b33d339/f497ef123f21aa5a451a86041.js");
      `;
      document.head.appendChild(mcScript);
    }

    // Cookies
      const cookieScript = document.createElement("script");
      cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
      cookieScript.setAttribute("data-document-language", "true");
      cookieScript.setAttribute("type", "text/javascript");
      cookieScript.setAttribute("data-domain-script", "e4dc7e5e-6254-4530-bd24-e376bd2e172e");
      document.head.appendChild(cookieScript);

      const optanonScript = document.createElement("script");
      optanonScript.type = "text/javascript";
      optanonScript.innerHTML = `
        function OptanonWrapper() { }
      `;
      document.head.appendChild(optanonScript);
    }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  const gclidParam = getParam(GoogleGlid, location.search);
  const gclsrcParam = getParam(GoogleSrc, location.search);
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;
  let gclidRecord: any = null;

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam);
    if (typeof window !== "undefined") {
      window.localStorage.setItem(GoogleGlid, JSON.stringify(gclidRecord));
    }
  }

  const gclid =
    gclidRecord || JSON.parse(window.localStorage.getItem(GoogleGlid) || "{}");
  const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

  if (!isGclidValid && typeof window !== "undefined") {
    window.localStorage.removeItem(GoogleGlid);
  }

  // Handle fbclid in the same way
  const fbclidParam = getParam(FacebookFbclid, location.search);
  let fbclidRecord: any = null;

  if (fbclidParam) {
    fbclidRecord = getExpiryRecord(fbclidParam);
    if (typeof window !== "undefined") {
      window.localStorage.setItem(FacebookFbclid, JSON.stringify(fbclidRecord));
    }
  }

  const fbclid =
    fbclidRecord ||
    JSON.parse(window.localStorage.getItem(FacebookFbclid) || "{}");
  const isFbclidValid = fbclid && new Date().getTime() < fbclid.expiryDate;

  if (!isFbclidValid && typeof window !== "undefined") {
    window.localStorage.removeItem(FacebookFbclid);
  }
};
